
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Auth } from "aws-amplify";
import * as Yup from "yup";
import router from "@/router";
import store from "@/store";
import { ElNotification } from "element-plus";
import { PASSWORD } from "@/helper/globalConst";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "newpassword",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      organizationRoles: "",
      customerRoles: "",
      currentpassword: "",
      newpassword: "",
      confirmpassword: "",
    };
  },
  methods: {
    async submitPassword() {
      Auth.completeNewPassword(store.getters.currentUser, this.newpassword)
        .then(() =>
          store
            .dispatch(Actions.LOGOUT)
            .then(() => router.push({ name: "sign-in" }))
            .then(() =>
              ElNotification({
                title: "Success",
                message: "Password Updated!",
                type: "success",
                duration: 2000,
                position: "top-right",
              })
            )
        )
        .catch((err) =>
          ElNotification({
            title: "Error",
            message: err.message,
            type: "error",
            duration: 2000,
            position: "top-right",
          })
        );
    },
  },
  setup() {
    const changePasswordButton = ref<HTMLElement | null>(null);

    const change = Yup.object().shape({
      newpassword: Yup.string()
        .min(8, "Password must be atleast 8 character")
        .matches(
          PASSWORD,
          "Must Contain atleast one Uppercase, One Lowercase, One Number [0-9] and atleast one special case Character @ $ % & ! *?#"
        )
        .required()
        .label("Password"),
      confirmpassword: Yup.string()
        .min(8)
        .required()
        .oneOf([Yup.ref("newpassword"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    const changePassword = () => {
      if (changePasswordButton.value) {
        // Activate indicator
        changePasswordButton.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          changePasswordButton.value?.removeAttribute("data-kt-indicator");

          Swal.fire({
            text: "Password is successfully changed!",
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          }).then(() => {
            // passwordFormDisplay.value = false;
          });
        }, 2000);
      }
    };
    return {
      change,
      changePasswordButton,
      changePassword,
    };
  },
});
